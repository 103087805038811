import { useEffect, useState } from "react";
import axios from "../api";
import { Link } from "react-router-dom";
import doveDormireWhite from "../../assets/icons/doveDormireWhite.svg";
import BarLoader from "react-spinners/BarLoader";
import { useMediaQuery } from "react-responsive";
import SearchBlue from "../../assets/icons/searchBlue.svg";
import {useTranslation} from "react-i18next";

export const DoveDormireCard = () => {
    const currentLanguage = localStorage.getItem("i18nextLng");
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const pageSize = 10; // numero di elementi per pagina
    const eventsPerPage = 50; // limite massimo di eventi da recuperare per ogni chiamata all'API
    const [hasMoreData, setHasMoreData] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `https://backend.isoladellefemmine.bbsitalia.com/${currentLanguage}/api/dove-dormire?page=${currentPage}&pageSize=${pageSize}`
                );
                setData((prevData) => [...prevData, ...response.data.rows]);
                setTotalPages(response.data.pager.total_pages);
                if (currentPage === response.data.pager.total_pages) {
                    setHasMoreData(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };

        if (hasMoreData && data.length < eventsPerPage) {
            fetchData();
        }
    }, [currentPage, currentLanguage, pageSize, hasMoreData]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 200 && hasMoreData) {
                setCurrentPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [hasMoreData]);

    const isDesktop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const [searchQuery, setSearchQuery] = useState("");
    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };
    const {t} = useTranslation();


return (
        <section className={'flex flex-wrap w-full justify-start h-full'}>

            {isDesktop ?

                <div className={'hidden'}>
                </div>
                :
                <div className={'flex flex-wrap h-[50px] w-full -mt-[40px] mb-5 relative z-[99999]'}>
                    <Link to="/cerca?category=dove_dormire" className={'w-full'}>

                        <img src={SearchBlue} alt={'SearchBlue'} className={'absolute left-4 top-1/2 transform -translate-y-1/2 z-20'} />
                        <input type={'text'} placeholder={t('cerca')} className={'text-left w-full h-[50px] px-5 pl-14 font-semibold relative z-10 rounded-[10px] shadow-isdfShadow'}/>
                    </Link>
                </div>
            }
            <div className={"flex flex-wrap overflow-auto h-full pb-5 w-full"}>


            {             data?.filter((event) =>
                    event.titolo.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map((doveDormireList, i) => (
                <div
                    key={i}
                    className={
                        "flex flex-wrap basis-1/2 lg:basis-1/3 lg:basis-1/3 rounded-[10px] p-2 mb-5"
                    }
                >
                    <Link to={`/dove-dormire/${doveDormireList.id}`} className={"w-full"}>
                        <div className={"shadow-isdfShadow rounded-[10px] w-full relative h-full"}>
                            <div className={"absolute bg-isdfMarkerArancione top-0 right-0 p-2 rounded-[10px]"}>
                                {isDesktop ?

                                    <img src={doveDormireWhite} className={"                            w-10 h-10"}/>
                                    :
                                    <img src={doveDormireWhite} className={"                            w-6 h-6"}/>
                                }
                            </div>
                            <img
                                src={`https://backend.isoladellefemmine.bbsitalia.com${doveDormireList.immagine_anteprima}`}
                                className={"w-full h-[250px] lg:h-[28rem] object-cover rounded-[10px]"}
                            />
                            <div className={"flex flex-wrap justify-center items-center px-4 py-2"}>
                                {isDesktop ?
                                    <h1
                                        className={
                                            "text-isdfBlue text-[25px] font-roboto_slab font-bold pt-3 pb-3"
                                        }
                                    >
                                        {doveDormireList.titolo}
                                    </h1>:
                                    <h1
                                    className={
                                    "text-isdfBlue text-[15px] font-roboto_slab font-bold pt-3 pb-3"
                                }
                                    >
                                {doveDormireList.titolo}
                                    </h1>

                                }
                            </div>
                        </div>
                    </Link>
                </div>
            ))}
            {isLoading && (
                <div className="flex justify-center items-center h-44">
                    <BarLoader color={"#2b6cb0"} size={25}/>
                </div>
            )}
            </div>
        </section>
    );
};
