import sfondoHeaderImage from "../assets/pexels-976866.png";
import {BackButton} from "../components/UI/BackButton";
import SearchBlue from "../assets/icons/searchBlue.svg";
import {useEffect, useState} from "react";
import axios from "axios";
import {DoveDormireCard} from "../components/doveDormire/DoveDormireCard";
import {useMediaQuery} from "react-responsive";
import {BackButtonWhite} from "../components/UI/BackButtonWhite";
import {NewsCard} from "../components/news/NewsCard";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";


export const News = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const currentLanguage = localStorage.getItem('i18nextLng');
        axios.get(`https://backend.isoladellefemmine.bbsitalia.com/${currentLanguage}/api/pagina-sezione/1810`)
            .then(response => setData(response.data[0]))
            .catch(error => console.log(error));
    }, []);

    console.log(data)

    const isDesktop = useMediaQuery({
        query: '(min-width: 1024px)'
    });
    const {t} = useTranslation();

    return(
        <section id={'ospitalitaESapori'} className={'h-screen overflow-hidden'}>
            <div className={'flex flex-wrap h-[28vh] content-center relative flex overflow-hidden'}>
                {
                    data && data.immagine_anteprima !== null ?
                        <img src={`https://backend.isoladellefemmine.bbsitalia.com/${data.immagine_anteprima}`} alt={'sfondoHeaderImage'} className={'object-cover w-full'}/>
                        :
                        <div className={'bg-gray-100 h-64'}/>
                }
                <div className="absolute inset-0 bg-black bg-opacity-60 flex justify-start items-center pl-8 pr-8">
                    {isDesktop ?
                        <div className="flex flex-wrap items-center w-full">
                            {/*<BackButton sfondo={'true'}/>*/}
                            {data && data.titolo &&
                                <h1 className="text-white font-open-sans text-[55px] font-semibold w-full text-center">
                                    {data.titolo}
                                </h1>
                            }
                            {data && data.body && data.body.length > 0 &&

                                <p className="text-white text-center text-[25px] w-full" dangerouslySetInnerHTML={{ __html: data.body }}></p>
                            }
                        </div> :
                        <div className="flex flex-wrap items-start">
                            <BackButton sfondo={'true'}/>
                            <div className="flex flex-col ml-4">
                                {data && data.titolo &&
                                    <h1 className="text-white font-open-sans text-[25px] font-semibold mb-10">
                                        {data.titolo}
                                    </h1>
                                }
                            </div>
                            {data && data.body && data.body.length > 0 &&

                                <p className="text-white text-left w-full" dangerouslySetInnerHTML={{ __html: data.body }}></p>
                            }
                        </div>
                    }
                </div>
            </div>
            {isDesktop ?

                <div className={'hidden'}>
                </div>
                :
                <div className={'flex flex-wrap px-8 h-[50px] -mt-[25px] relative'}>
                    <Link to="/cerca?category=news" className={'w-full'}>

                    <img src={SearchBlue} alt={'SearchBlue'} className={'absolute left-12 top-1/2 transform -translate-y-1/2 z-20'} />
                    <input type={'text'} placeholder={t('cerca')} className={'text-left w-full h-[50px] px-5 pl-14 font-semibold relative z-10 rounded-[10px] shadow-isdfShadow'}/>
                    </Link>
                </div>
            }

            {isDesktop ?
            <div className={'flex flex-wrap justify-start items-start mt-0 py-5 px-8 h-[62vh]'}>
                <NewsCard/>
            </div>
                :
                <div className={'flex flex-wrap justify-start items-start mt-0 py-5 px-8 h-[66vh]'}>
                    <NewsCard/>
                </div>
            }

            {isDesktop ?
                <div className={'bg-isdfBlue h-[10vh] b-t-l-r-15 content-center flex flex-wrap items-center p-8'}>
                    <BackButtonWhite sfondo={'true'}/>
                </div>
                :
                <div className={'hidden'}/>
            }
        </section>
    )
}