import sfondoHeaderImage from "../assets/pexels-976866.png";
import {BackButton} from "../components/UI/BackButton";
import SearchBlue from "../assets/icons/searchBlue.svg";
import {useEffect, useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import doveDormireIcon from "../assets/icons/doveDormire.svg";
import doveMangiareIcon from "../assets/icons/doveMangiare.svg";
import shoppingIcon from "../assets/icons/shopping.svg";
import pescatoLocalIcon from "../assets/icons/pescatoLocale.svg";
import pasticceriaIcon from "../assets/icons/pasticceria.svg";
import piattiTradizionaliIcon from "../assets/icons/piattiTradizionali.svg";
import streetFoodIcon from "../assets/icons/streetFood.svg";
import decoIcon from "../assets/icons/deco.svg";

import {useMediaQuery} from "react-responsive";
import {BackButtonWhite} from "../components/UI/BackButtonWhite";

import {useTranslation} from "react-i18next";

export const OspitalitaESapori = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const currentLanguage = localStorage.getItem('i18nextLng');
        axios.get(`https://backend.isoladellefemmine.bbsitalia.com/${currentLanguage}/api/pagina-sezione/846`)
            .then(response => setData(response.data[0]))
            .catch(error => console.log(error));
    }, []);

    console.log(data)
    const isDesktop = useMediaQuery({
        query: '(min-width: 1024px)'
    });

    const {t} = useTranslation()

    return(
        <section id={'ospitalitaESapori'}>
            <div className={'flex flex-wrap h-[28vh] content-center relative flex overflow-hidden'}>
                {
                    data && data.immagine_anteprima !== null ?
                        <img src={`https://backend.isoladellefemmine.bbsitalia.com/${data.immagine_anteprima}`} alt={'sfondoHeaderImage'} className={'object-cover w-full'}/>
                        :
                        <div className={'bg-gray-100 h-64'}/>
                }
                <div className="absolute inset-0 bg-black bg-opacity-60 flex justify-start items-center pl-8 pr-8">
                    {isDesktop ?
                        <div className="flex flex-wrap items-center w-full">
                            {/*<BackButton sfondo={'true'}/>*/}
                            {data && data.titolo &&
                                <h1 className="text-white font-open-sans text-[55px] font-semibold w-full text-center">
                                    {data.titolo}
                                </h1>
                            }
                            {data && data.body && data.body.length > 0 &&

                                <p className="text-white text-center text-[25px] w-full" dangerouslySetInnerHTML={{ __html: data.body }}></p>
                            }
                        </div> :
                        <div className="flex flex-wrap items-start">
                            <BackButton sfondo={'true'}/>
                            <div className="flex flex-col ml-4">
                                {data && data.titolo &&
                                    <h1 className="text-white font-open-sans text-[25px] font-semibold mb-10">
                                        {data.titolo}
                                    </h1>
                                }
                            </div>
                            {data && data.body && data.body.length > 0 &&

                                <p className="text-white text-left w-full" dangerouslySetInnerHTML={{ __html: data.body }}></p>
                            }
                        </div>
                    }
                </div>
            </div>

            {isDesktop ?

            <div className={'flex flex-wrap mt-10 py-5 px-8 h-[60vh]'}>
                <Link to={'/pescato-locale'} className={'w-1/2 mb-10 block pr-2'}>
                    <div className={'flex flex-wrap justify-start items-center shadow-isdfShadow px-5 py-10 rounded-[10px]'}>
                        <img src={pescatoLocalIcon} alt={'placeholder'} className={'w-32 m-auto mb-10'}/>
                        <span className={'text-isdfBlue font-roboto_slab text-[35px] font-bold w-full'}>Pescato locale</span>
                    </div>
                </Link>
                <Link to={'/pasticceria'} className={'w-1/2 mb-10 block pr-2'}>
                    <div className={'flex flex-wrap justify-start items-center shadow-isdfShadow px-5 py-10 rounded-[10px]'}>
                        <img src={pasticceriaIcon} alt={'placeholder'} className={'w-32 m-auto mb-10'}/>
                        <span className={'text-isdfBlue font-roboto_slab text-[35px] font-bold w-full'}>Pasticceria</span>
                    </div>
                </Link>
                <Link to={'/piatti-tradizionali'} className={'w-1/2 mb-10 block pr-2'}>
                    <div className={'flex flex-wrap justify-start items-center shadow-isdfShadow px-5 py-10 rounded-[10px]'}>
                        <img src={piattiTradizionaliIcon} alt={'placeholder'} className={'w-32 m-auto mb-10'}/>
                        <span className={'text-isdfBlue font-roboto_slab text-[35px] font-bold w-full'}>Piatti tradizionali</span>
                    </div>
                </Link>
                <Link to={'/street-food'} className={'w-1/2 mb-10 block pr-2'}>
                    <div className={'flex flex-wrap justify-start items-center shadow-isdfShadow px-5 py-10 rounded-[10px]'}>
                        <img src={streetFoodIcon} alt={'placeholder'} className={'w-32 m-auto mb-10'}/>
                        <span className={'text-isdfBlue font-roboto_slab text-[35px] font-bold w-full'}>Street food</span>
                    </div>
                </Link>
                <Link to={'/deco'} className={'w-1/2 mb-10 block pr-2'}>
                    <div className={'flex flex-wrap justify-start items-center shadow-isdfShadow px-5 py-10 rounded-[10px]'}>
                        <img src={decoIcon} alt={'placeholder'} className={'w-32 m-auto mb-10'}/>
                        <span className={'text-isdfBlue font-roboto_slab text-[35px] font-bold w-full'}>DE.C.O</span>
                    </div>
                </Link>
            </div> :
                <div className={'flex flex-wrap justify-center items-center mt-10 py-5 px-8'}>
                    <Link to={'/pescato-locale'} className={'w-full mb-8'}>
                        <div className={'flex flex-wrap justify-start items-center shadow-isdfShadow px-5 py-10 rounded-[10px]'}>
                            <img src={pescatoLocalIcon} alt={'placeholder'} className={'mr-8'}/>
                            <span className={'text-isdfBlue font-roboto_slab text-[20px] font-bold'}>{t('pescatolocale')}</span>
                        </div>
                    </Link>
                    <Link to={'/pasticceria'} className={'w-full mb-8'}>
                        <div className={'flex flex-wrap justify-start items-center shadow-isdfShadow px-5 py-10 rounded-[10px]'}>
                            <img src={pasticceriaIcon} alt={'placeholder'} className={'mr-8'}/>
                            <span className={'text-isdfBlue font-roboto_slab text-[20px] font-bold'}>{t('pasticceria')}</span>
                        </div>
                    </Link>
                    <Link to={'/piatti-tradizionali'} className={'w-full'}>
                        <div className={'flex flex-wrap justify-start items-center shadow-isdfShadow px-5 py-10 rounded-[10px]'}>
                            <img src={piattiTradizionaliIcon} alt={'placeholder'} className={'mr-8'}/>
                            <span className={'text-isdfBlue font-roboto_slab text-[20px] font-bold'}>{t('piattitradizionali')}</span>
                        </div>
                    </Link>
                    <Link to={'/street-food'} className={'w-full'}>
                        <div className={'flex flex-wrap justify-start items-center shadow-isdfShadow px-5 py-10 rounded-[10px]'}>
                            <img src={streetFoodIcon} alt={'placeholder'} className={'mr-8'}/>
                            <span className={'text-isdfBlue font-roboto_slab text-[20px] font-bold'}>{t('streetfood')}</span>
                        </div>
                    </Link>
                    <Link to={'/deco'} className={'w-full'}>
                        <div className={'flex flex-wrap justify-start items-center shadow-isdfShadow px-5 py-10 rounded-[10px]'}>
                            <img src={decoIcon} alt={'placeholder'} className={'mr-8'}/>
                            <span className={'text-isdfBlue font-roboto_slab text-[20px] font-bold'}>{t('deco')}</span>
                        </div>
                    </Link>
                </div>
            }



            {isDesktop ?
                <div className={'bg-isdfBlue h-[10vh] b-t-l-r-15 content-center flex flex-wrap items-center p-8'}>
                    <BackButtonWhite sfondo={'true'}/>
                </div>
                :
                <div className={'hidden'}/>
            }


        </section>
    )
}
