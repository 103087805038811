import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import TotemComponent from "./TotemComponent";
import AppComponent from "./AppComponent";
import { useMediaQuery } from 'react-responsive';
import Screensaver from "./pages/Screensaver";
import videoSource from "./assets/video.mp4";
import axios from "axios";
import { NewsCardDetailPrimoPiano } from "./components/news/NewsCardDetailPrimoPiano";

function App() {
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [videoEnded, setVideoEnded] = useState(false);
    const [videoClicked, setVideoClicked] = useState(false);

    const [avvisoPrimoPiano, setAvvisoPrimoPiano] = useState(false);
    const [avvisoPrimoPianoData, setAvvisoPrimoPianoData] = useState([]);

    useEffect(() => {
        const fetchData = () => {
            axios.get(`https://backend.isoladellefemmine.bbsitalia.com/it/api/contenuto-singolo-primo-piano`)
                .then(response => {
                    if (response.data && response.data.rows && response.data.pager) {
                        const total = parseInt(response.data.pager.total_items, 10);
                        setAvvisoPrimoPiano(total > 0);
                        setAvvisoPrimoPianoData(response.data);
                    } else {
                        setAvvisoPrimoPiano(false);
                    }
                })
                .catch(error => {
                    console.error("Errore nella fetch:", error);
                });
        };

        // Esegui la fetch iniziale
        fetchData();

        // Imposta l'intervallo per le fetch periodiche ogni 10 minuti
        const interval = setInterval(fetchData, 600000);

        return () => clearInterval(interval);
    }, []);

    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });
    const videoRef = useRef(null);

    useEffect(() => {
        setVideoEnded(sessionStorage.getItem('videoEnded'));
    }, []);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.addEventListener('loadeddata', handleVideoLoad);
            videoElement.addEventListener('ended', handleVideoEnd);
        }

        return () => {
            if (videoElement) {
                videoElement.removeEventListener('loadeddata', handleVideoLoad);
                videoElement.removeEventListener('ended', handleVideoEnd);
            }
        };
    }, []);

    const handleVideoLoad = () => setVideoLoaded(true);
    const handleVideoEnd = () => {
        setVideoEnded(true);
        sessionStorage.setItem('videoEnded', true);
    };

    const handleVideoClick = () => {
        if (!videoClicked && videoRef.current) {
            const targetTime = videoRef.current.duration - 2;
            videoRef.current.currentTime = targetTime;
            videoRef.current.play();
            setVideoClicked(true);
        }
    };

    return (
        <div className="App h-screen">
            {avvisoPrimoPiano && avvisoPrimoPianoData && avvisoPrimoPianoData.rows && avvisoPrimoPianoData.rows[0] ? (
                <NewsCardDetailPrimoPiano data={avvisoPrimoPianoData.rows[0]} />
            ) : (
                <>
                    {isDesktop ? (
                        <Screensaver />
                    ) : (
                        !videoEnded && (
                            <video
                                ref={videoRef}
                                className={`video ${videoLoaded ? 'h-100vh w-full object-cover' : 'hidden'}`}
                                playsInline
                                muted
                                autoPlay
                                onEnded={handleVideoEnd}
                                onClick={handleVideoClick}
                                onTouchStart={handleVideoClick}
                                src={videoSource}
                                style={{ width: '100%', height: '100%' }}
                                controls={false}
                            />
                        )
                    )}
                    {isDesktop && <TotemComponent />}
                </>
            )}
        </div>
    );

}

export default App;
