import { useEffect, useState } from "react";
import axios from "../api";
import { Link } from "react-router-dom";
import doveDormireWhite from "../../assets/icons/museiECulturaWhite.svg";
import BarLoader from "react-spinners/BarLoader";
import {useMediaQuery} from "react-responsive";

export const MuseiECulturaCard = () => {
    const currentLanguage = localStorage.getItem("i18nextLng");
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const pageSize = 10; // numero di elementi per pagina
    const eventsPerPage = 50; // limite massimo di eventi da recuperare per ogni chiamata all'API
    const [hasMoreData, setHasMoreData] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(
                `https://backend.isoladellefemmine.bbsitalia.com/${currentLanguage}/api/musei-e-cultura?page=${currentPage}&pageSize=${pageSize}`
            )
            .then((response) => {
                setData((prevData) => [...prevData, ...response.data.rows]);
                setTotalPages(response.data.pager.total_pages);
                if (currentPage === response.data.pager.total_pages) {
                    setHasMoreData(false);
                }
                setIsLoading(false);
            })
            .catch((error) => console.log(error));
    }, [currentPage, currentLanguage, pageSize]);

    async function fetchAllEvents() {
        const allEvents = [];
        const response = await axios.get(
            `https://backend.isoladellefemmine.bbsitalia.com/${currentLanguage}/api/musei-e-cultura?page=1&pageSize=1`
        );
        const totalEvents = response.data.pager.total_items;
        const maxEventsToFetch = Math.min(eventsPerPage, totalEvents - data.length);
        const maxPagesToFetch = Math.ceil(maxEventsToFetch / pageSize);
        for (let i = 0; i <= maxPagesToFetch; i++) {
            const response = await axios.get(
                `https://backend.isoladellefemmine.bbsitalia.com/${currentLanguage}/api/musei-e-cultura?page=${i}&pageSize=${pageSize}`
            );
            allEvents.push(...response.data.rows);
        }
        return allEvents;
    }

    useEffect(() => {
        if (hasMoreData && data.length < eventsPerPage) {
            setIsLoading(true);
            fetchAllEvents().then((allEvents) => {
                setData(allEvents);
                const totalEvents = allEvents.length;
                setHasMoreData(totalEvents < totalEvents);
                setIsLoading(false);
            });
        }
    }, [data.length, eventsPerPage, hasMoreData, currentLanguage]);
    const isDesktop = useMediaQuery({
        query: '(min-width: 1024px)'
    });
    return (
        <div className={"flex flex-wrap overflow-auto h-full pb-5"}>
            {data.map((doveDormireList, i) => (
                <div
                    key={i}
                    className={
                        "flex flex-wrap basis-1/2 lg:basis-1/3 rounded-[10px] p-2 mb-5"
                    }
                >
                    <Link to={`/musei-e-cultura/${doveDormireList.id}`} className={"w-full"}>
                        <div className={"shadow-isdfShadow rounded-[10px] w-full relative h-full"}>
                            {/*<div className={"absolute bg-isdfMarkerRosso top-0 right-0 p-2 rounded-[10px]"}>*/}
                                {/*{isDesktop ?*/}
                                {/**/}
                                {/*    <img src={doveDormireWhite} className={"                            w-10 h-10"}/>*/}
                                {/*    :*/}
                                {/*    <img src={doveDormireWhite} className={"                            w-6 h-6"}/>*/}
                                {/*}*/}
                            {/*</div>*/}
                            <img
                                src={`https://backend.isoladellefemmine.bbsitalia.com${doveDormireList.immagine_anteprima}`}
                                className={"w-full h-[250px] lg:h-[28rem] object-cover rounded-[10px]"}
                            />
                            <div className={"flex flex-wrap justify-center items-center px-4 py-2"}>
                                {isDesktop ?
                                    <h1
                                        className={
                                            "text-isdfBlue text-[25px] font-roboto_slab font-bold pt-3 pb-3"
                                        }
                                    >
                                        {doveDormireList.titolo}
                                    </h1>:
                                    <h1
                                        className={
                                            "text-isdfBlue text-[15px] font-roboto_slab font-bold pt-3 pb-3"
                                        }
                                    >
                                        {doveDormireList.titolo}
                                    </h1>

                                }
                            </div>
                        </div>
                    </Link>
                </div>
            ))}
            {isLoading && (
                <div className="flex justify-center items-center h-44">
                    <BarLoader color={"#2b6cb0"} size={25}/>
                </div>
            )}
        </div>
    );
};
